/* eslint-disable react/prefer-stateless-function */
import {Helmet} from 'react-helmet-async';
import {connect} from 'react-redux';
import React, {Component} from 'react';
import PropTypes from 'prop-types';

import MarketLayout from 'web/components/market_layout';
import MinimalLayout from 'web/components/minimal_layout';
import StepsHeader from 'web/basket/components/steps_header';

import {reducer, actions} from './duck';
import SignUp from './sign_up';

class SignUpPage extends Component {
  render() {
    const Layout = this.props.useMinimalLayout ? MinimalLayout : MarketLayout;
    return (
      <Layout>
        <Helmet>
          <title>Sign Up | Good Eggs</title>
          {/* eslint-disable-next-line jsx-a11y/html-has-lang */}
          {this.props.useMinimalLayout && <html className="with-fixed-minimal-footer" />}
          <meta
            name="google-signin-client_id"
            content={this.props.settings.googleAuthenticateClientId}
          />
        </Helmet>

        {this.props.useMinimalLayout ? <StepsHeader currentStep={1} /> : null}
        <div className="sign-up-page narrow-page">
          {this.props.useMinimalLayout ? (
            <div className="sign-up-page__intro">Sign in or sign up to continue</div>
          ) : null}
          <SignUp
            headingText="Create your account"
            error={this.props.error}
            working={this.props.working}
            submitSignUpForm={this.props.submitSignUpForm.bind(this)}
            signInUrl={this.props.signInUrl}
          />
        </div>
      </Layout>
    );
  }
}

SignUpPage.propTypes = {
  submitSignUpForm: PropTypes.func,
  loginWithGoogle: PropTypes.func,
  working: PropTypes.bool,
  error: PropTypes.object,
  signInUrl: PropTypes.string,
  destinationUrl: PropTypes.string,
  useMinimalLayout: PropTypes.bool,
  settings: PropTypes.shape({
    googleAuthenticateClientId: PropTypes.string.isRequired,
  }).isRequired,
};

SignUpPage.reducer = reducer;

SignUpPage.pageName = 'Sign Up';

export function mapStateToProps(state) {
  return {
    working: state.signUpForm.isSaving,
    error: state.signUpForm.error || state.socialSignIn.error,
    signInUrl: state.signUpPage.signInUrl,
    destinationUrl: state.signUpPage.destinationUrl,
    useMinimalLayout: state.signUpPage.useMinimalLayout,
    settings: state.settings,
  };
}

export default connect(mapStateToProps, actions)(SignUpPage);
